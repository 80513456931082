import style from "./Chinh_Sach_Bao_Mat.module.scss";
import classNames from "classnames/bind";

const cx = classNames.bind(style);

function Chinh_Sach_Bao_Mat() {
	return (
		<div className={cx("wrapper")}>
			<div className={cx("inner")}>
				<div className={cx("title")}>Chính sách bảo mật</div>
				<div className={cx("content")}>
					<h6>
						pixelshop.com.vn cam kết sẽ bảo mật những thông tin mang tính riêng tư của bạn.
						Bạn vui lòng đọc bản “Chính sách bảo mật” dưới đây để hiểu hơn những cam kết mà
						chúng tôi thực hiện, nhằm tôn trọng và bảo vệ quyền lợi của người truy cập.
					</h6>
					<h5>1. Mục đích và phạm vi thu thập</h5>
					<h6>
						Để truy cập và sử dụng một số dịch vụ tại pixelshop.com.vn, bạn có thể sẽ được
						yêu cầu đăng ký với chúng tôi thông tin cá nhân (Email, Họ tên, Số ĐT liên
						lạc…). Mọi thông tin khai báo phải đảm bảo tính chính xác và hợp pháp.
						pixelshop.com.vn không chịu mọi trách nhiệm liên quan đến pháp luật của thông
						tin khai báo.
					</h6>
					<h6>
						Chúng tôi cũng có thể thu thập thông tin về số lần viếng thăm, bao gồm số trang
						bạn xem, số links (liên kết) bạn click và những thông tin khác liên quan đến
						việc kết nối đến site pixelshop.com.vn. Chúng tôi cũng thu thập các thông tin mà
						trình duyệt Web (Browser) bạn sử dụng mỗi khi truy cập vào pixelshop.com.vn, bao
						gồm: địa chỉ IP, loại Browser, ngôn ngữ sử dụng, thời gian và những địa chỉ mà
						Browser truy xuất đến.
					</h6>

					<h5>2. Phạm vi sử dụng thông tin</h5>
					<h6>
						pixelshop.com.vn thu thập và sử dụng thông tin cá nhân bạn với mục đích phù hợp
						và hoàn toàn tuân thủ nội dung của “Chính sách bảo mật” này. Khi cần thiết,
						chúng tôi có thể sử dụng những thông tin này để liên hệ trực tiếp với bạn dưới
						các hình thức như: gởi thư ngỏ, đơn đặt hàng, thư cảm ơn, sms, thông tin về kỹ
						thuật và bảo mật…
					</h6>

					<h5>3. Thời gian lưu trữ thông tin</h5>
					<h6>
						Dữ liệu cá nhân của Thành viên sẽ được lưu trữ cho đến khi có yêu cầu hủy bỏ
						hoặc tự thành viên đăng nhập và thực hiện hủy bỏ. Còn lại trong mọi trường hợp
						thông tin cá nhân thành viên sẽ được bảo mật trên máy chủ của pixelshop.com.vn.
					</h6>

					<h5>4. Địa chỉ của đơn vị thu thập và quản lý thông tin cá nhân</h5>
					<h6>Công Ty Cổ Phần Bán Lẻ Kỹ Thuật Số FPT</h6>
					<h6>
						Địa chỉ đăng ký kinh doanh: 261 - 263 Khánh Hội, P2, Q4, TP. Hồ Chí Minh
					</h6>
					<h6>Văn phòng: 261 - 263 Khánh Hội, P2, Q4, TP. Hồ Chí Minh</h6>
					<h6>Điện thoại văn phòng: 028.38345837</h6>

					<h5>
						5. Phương tiện và công cụ để người dùng tiếp cận và chỉnh sửa dữ liệu cá nhân
					</h5>
					<h6>
						Hiện website chưa triển khai trang quản lý thông tin cá nhân của thành viên, vì
						thế việc tiếp cận và chỉnh sửa dữ liệu cá nhân dựa vào yêu cầu của khách hàng
						bằng cách hình thức sau:
					</h6>
					<h6>
						Gọi điện thoại đến tổng đài chăm sóc khách hàng 1800 6601, bằng nghiệp vụ chuyên
						môn xác định thông tin cá nhân và nhân viên tổng đài sẽ hỗ trợ chỉnh sửa thay
						người dùng
					</h6>
					<h6>
						Để lại bình luận hoặc gửi góp ý trực tiếp từ website pixelshop.com.vn, quản trị
						viên kiểm tra thông tin và xem xét nội dung bình luận có phù hợp với pháp luật
						và chính sách của pixelshop.com.vn
					</h6>

					<h5>6. Cam kết bảo mật thông tin cá nhân khách hàng</h5>
					<h6>
						Thông tin cá nhân của thành viên trên pixelshop.com.vn được pixelshop.com.vn cam
						kết bảo mật tuyệt đối theo chính sách bảo vệ thông tin cá nhân của
						pixelshop.com.vn. Việc thu thập và sử dụng thông tin của mỗi thành viên chỉ được
						thực hiện khi có sự đồng ý của khách hàng đó trừ những trường hợp pháp luật có
						quy định khác.
					</h6>
					<h6>
						Không sử dụng, không chuyển giao, cung cấp hay tiết lộ cho bên thứ 3 nào về
						thông tin cá nhân của thành viên khi không có sự cho phép đồng ý từ thành viên.
					</h6>
					<h6>
						Trong trường hợp máy chủ lưu trữ thông tin bị hacker tấn công dẫn đến mất mát dữ
						liệu cá nhân thành viên, pixelshop.com.vn sẽ có trách nhiệm thông báo vụ việc
						cho cơ quan chức năng điều tra xử lý kịp thời và thông báo cho thành viên được
						biết.
					</h6>
					<h6>
						Bảo mật tuyệt đối mọi thông tin giao dịch trực tuyến của Thành viên bao gồm
						thông tin hóa đơn kế toán chứng từ số hóa tại khu vực dữ liệu trung tâm an toàn
						cấp 1 của pixelshop.com.vn.
					</h6>
					<h6>
						Hệ thống thanh toán thẻ được cung cấp bởi các đối tác cổng thanh toán (“Đối Tác
						Cổng Thanh Toán”) đã được cấp phép hoạt động hợp pháp tại Việt Nam. Theo đó, các
						tiêu chuẩn bảo mật thanh toán thẻ tại Pixel Shop đảm bảo tuân thủ theo các tiêu
						chuẩn bảo mật ngành.
					</h6>
					<h6>
						Ban quản lý pixelshop.com.vn yêu cầu các cá nhân khi đăng ký/mua hàng là thành
						viên, phải cung cấp đầy đủ thông tin cá nhân có liên quan như: Họ và tên, địa
						chỉ liên lạc, email, số chứng minh nhân dân, điện thoại, số tài khoản, số thẻ
						thanh toán …., và chịu trách nhiệm về tính pháp lý của những thông tin trên. Ban
						quản lý pixelshop.com.vn không chịu trách nhiệm cũng như không giải quyết mọi
						khiếu nại có liên quan đến quyền lợi của Thành viên đó nếu xét thấy tất cả thông
						tin cá nhân của thành viên đó cung cấp khi đăng ký ban đầu là không chính xác.
					</h6>

					<h5>7. Quy định bảo mật</h5>
					<h6>
						Chính sách giao dịch thanh toán bằng thẻ quốc tế và thẻ nội địa (internet
						banking) đảm bảo tuân thủ các tiêu chuẩn bảo mật của các Đối Tác Cổng Thanh Toán
						gồm:
					</h6>
					<h6>
						Thông tin tài chính của Khách hàng sẽ được bảo vệ trong suốt quá trình giao dịch
						bằng giao thức SSL 256-bit (Secure Sockets Layer).
					</h6>
					<h6>
						Mật khẩu sử dụng một lần (OTP) được gửi qua SMS để đảm bảo việc truy cập tài
						khoản được xác thực.
					</h6>
					<h6>
						Các nguyên tắc và quy định bảo mật thông tin trong ngành tài chính ngân hàng
						theo quy định của Ngân hàng nhà nước Việt Nam.
					</h6>
					<h6>
						Chính sách bảo mật giao dịch trong thanh toán của Pixel Shop áp dụng với Khách
						hàng:
					</h6>
					<h6>
						Thông tin thẻ thanh toán của Khách hàng mà có khả năng sử dụng để xác lập giao
						dịch KHÔNG được lưu trên hệ thống của Pixel Shop. Đối Tác Cổng Thanh Toán sẽ lưu
						giữ và bảo mật theo tiêu chuẩn quốc tế PCI DSS.
					</h6>
					<h6>
						Đối với thẻ nội địa (internet banking), Pixel Shop chỉ lưu trữ mã đơn hàng, mã
						giao dịch và tên ngân hàng. Pixel Shop cam kết đảm bảo thực hiện nghiêm túc các
						biện pháp bảo mật cần thiết cho mọi hoạt động thanh toán thực hiện trên trang
						Pixel Shop.
					</h6>

					<h5>8. Làm cách nào để yêu cầu xóa dữ liệu?</h5>
					<h6>
						Bạn có thể gửi yêu cầu xóa dữ liệu qua email Trung tâm hỗ trợ của chúng tôi:
						Pixel Shop@fpt.com.vn. Vui lòng cung cấp càng nhiều thông tin càng tốt về dữ
						liệu nào bạn muốn xóa. Yêu cầu sẽ được chuyển đến nhóm thích hợp để đánh giá và
						xử lý. Chúng tôi sẽ liên hệ từng bước để cập nhật cho bạn về tiến trình xóa.
					</h6>
				</div>
			</div>
		</div>
	);
}

export default Chinh_Sach_Bao_Mat;

import style from "./Cau_Hoi_Thuong_Gap.module.scss";
import classNames from "classnames/bind";

const cx = classNames.bind(style);

function Cau_Hoi_Thuong_Gap() {
	return <div className={cx("wrapper")}>aaaaaaaaa</div>;
}

export default Cau_Hoi_Thuong_Gap;
